














































import {Component, Vue, Prop, Watch} from 'vue-property-decorator'
import CourseOrderApi from '@/api/course/courseOrder'

@Component({})
export default class CourseBuyList extends Vue {
  @Prop({default: () => false}) private visible!: boolean
  @Prop({default: () => ''}) private title!: string
  @Prop({default: () => ''}) private courseId!: string

  private orderApi = new CourseOrderApi()
  private tempCourseId = ''
  private tableData = new Array()

  @Watch('courseId', {immediate: true})
  private getMemberId(nv: string, ov: string) {
    if (nv) {
      this.tempCourseId = nv
    }
  }

  private async mounted() {
    if (this.tempCourseId) {
      await this.getList()
    }
  }

  private async getList() {
    const result = await this.orderApi.getCourseOrderByCourseIdAsync(this.tempCourseId)
    this.tableData = result
  }

  private async handleQueryOrder(row: any) {
    const result = await this.orderApi.queryOrder(row.id)
    this.$notify({message: result, title: ''})
  }

  private async handleTuiKuan(row: any) {
    const result = await this.orderApi.refundAsync(row.id)
    await this.getList()
  }

  private async handleTuiKuanQuery(row: any) {
    const result = await this.orderApi.refundQueryAsync(row.id)
    this.$notify({message: result, title: ''})
  }

  private async handleKaiKe(row: any) {
    await this.orderApi.updateOrderCourseStateAsync(row.id);
    await this.getList()
  }

  private handleClose() {
    this.$emit('handleClose')
  }
}
